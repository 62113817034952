import React from 'react';
import { Waypoint } from 'react-waypoint';
import './waypointLazyRender.scss';

interface WaypointLazyRenderProps {
    initialIsVisible?: boolean;
    emptyStateStyles?: Object;
    onEnter?: Function;
    bottomOffset?: string;
    onIsVisible?: Function;
}

export const WaypointLazyRender: React.FunctionComponent<
    WaypointLazyRenderProps
> = (props) => {
    const {
        initialIsVisible = false,
        emptyStateStyles = { height: '100px' },
        bottomOffset = '-100px',
        onEnter,
    } = props;
    const [isVisible, setIsVisible] = React.useState(initialIsVisible);

    return (
        <Waypoint
            scrollableAncestor={'window'}
            bottomOffset={bottomOffset}
            onEnter={() => {
                setIsVisible(true);
                onEnter?.();
            }}
        >
            <div
                className={`waypoint-lazy-render ${
                    isVisible ? 'waypoint-lazy-render--visible' : ''
                }`}
            >
                {isVisible ? props.children : <div style={emptyStateStyles} />}
            </div>
        </Waypoint>
    );
};
