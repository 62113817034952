import { SimpleCarousel } from '@eventbrite/collection-carousel';
import { track } from '@eventbrite/datalayer-library';
import {
    DEFAULT_DESTINATION_EXPANSIONS,
    FormattedEvent,
} from '@eventbrite/event-renderer';
import { LoadingSkeleton } from '@eventbrite/loading-skeleton';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { connect } from 'react-redux';
import { searchOrganicEvents } from '../../../../api/searchOrganicEvents';
import DiscoverVerticalEventCard from '../../../../components/DiscoverEventCard/DiscoverVerticalEventCard';
import { ConnectedProps, DiscoverState } from '../../../../types/index';
import { transformResponse } from '../../../categoryBrowse/hooks/queries/events';
import { HOMEPAGE_SEASONAL_COLLECTION_EVENTS } from '../../constants/analytics';
import {
    holiday_seasonal_class,
    nye_seasonal_class,
    seasonalMapping,
} from '../../constants/category';
import { useIsTabletDevice } from '../../containers/HomepageBannerCarousel/hooks';

import styles from './SeasonalCollectionEventsContainer.module.scss';

export const SeasonalCollectionEvents = ({
    isAuthenticated,
    isMobile,
    location,
    shareOptions,
    locale,
    featureFlags,
}: ReduxProps) => {
    const getSeasonalClass = () => {
        return featureFlags.enableLighthouseCreatorsModule
            ? nye_seasonal_class
            : holiday_seasonal_class;
    };
    const seasonalClass = getSeasonalClass();
    const { title: SEASONAL_TITLE, subtitle: SEASONAL_SUBTITLE } =
        seasonalMapping[seasonalClass];

    const TitleDescription = React.memo(
        ({ currentPlace }: { currentPlace: string }) => {
            return (
                <div className={styles.titleSubtitleContainer}>
                    <h2 className={`${styles.h2} ${styles[seasonalClass]}`}>
                        {SEASONAL_TITLE}
                    </h2>

                    <h3 className={`${styles.h3} ${styles[seasonalClass]}`}>
                        <span
                            className={styles[seasonalClass]}
                        >{`${currentPlace.toUpperCase()}`}</span>{' '}
                        {SEASONAL_SUBTITLE}
                    </h3>
                </div>
            );
        },
    );

    React.useEffect(() => {
        if (location && location.currentPlace) {
            track({
                eventName: 'SeasonalCollectionEventsView',
                eventData: {
                    searchLocation: location.currentPlace,
                },
            });
        }
    }, [location]);

    const currentPlaceId = location?.placeId ? location.placeId : '';
    const currentPlace = location?.currentPlace ? location.currentPlace : '';

    // Collection query for seasonal collection events
    const collection = useQuery(
        ['homepage', location],
        () =>
            searchOrganicEvents({
                event_search: {
                    places: [currentPlaceId],
                    dates: ['current_future'],
                    page_size: 50,
                    include_mature_events: true,
                    include_manual_events: true,
                    aggs: {},
                    collections_or: seasonalMapping[seasonalClass].collection,
                    sort: 'date',
                    available: true,
                    image: true,
                },
                browse_surface: 'homepage',
                'expand.destination_event': DEFAULT_DESTINATION_EXPANSIONS,
            }),
        {
            select: transformResponse,
            refetchOnMount: false,
            enabled: true,
        },
    );

    const isTablet = useIsTabletDevice();
    const mobileHeight = '614px';
    const tabletHeight = '630px';
    const desktopHeight = '384px';
    if (collection.isLoading) {
        return (
            <div>
                <LoadingSkeleton
                    height={
                        isMobile
                            ? mobileHeight
                            : isTablet
                            ? tabletHeight
                            : desktopHeight
                    }
                />
            </div>
        );
    }

    if (!collection.data || collection.data?.events.length < 3) return null;

    const events: FormattedEvent[] =
        collection.data?.events.length !== 0 ? collection.data.events : [];

    const showNavigation = isTablet ? events.length > 2 : events.length > 3;

    const {
        seasonalCollection,
        seasonalContainer,
        simpleCarouselParent,
        seasonalEventCard,
    } = styles;

    return (
        <div className={seasonalCollection}>
            <div className={`${seasonalContainer} ${styles[seasonalClass]}`}>
                <TitleDescription currentPlace={currentPlace} />
                <div className={simpleCarouselParent}>
                    <SimpleCarousel
                        useSideNavigation={showNavigation}
                        slidesVisible={{ desktop: 3, tablet: 2 }}
                    >
                        {events.map((event) => (
                            <div
                                className={seasonalEventCard}
                                data-heap-id="seasonal-collection-event-card-click"
                                key={event.id}
                            >
                                <DiscoverVerticalEventCard
                                    event={event}
                                    shareOptions={shareOptions}
                                    isAuthenticated={isAuthenticated}
                                    affCode="ehomefeatured"
                                    statsigLocationString={HOMEPAGE_SEASONAL_COLLECTION_EVENTS(
                                        seasonalClass,
                                    )}
                                    locale={locale}
                                    isLazyImage
                                />
                            </div>
                        ))}
                    </SimpleCarousel>
                </div>
            </div>
        </div>
    );
};

const _mapStateToProps = ({
    app,
    user,
    location,
    shareOptions,
}: DiscoverState) => {
    return {
        isMobile: app.isMobile,
        isAuthenticated: user.isAuthenticated,
        location: location,
        shareOptions: shareOptions,
        locale: app.locale,
        featureFlags: app.featureFlags,
    };
};

type ReduxProps = ConnectedProps<typeof _mapStateToProps>;
export default connect(_mapStateToProps)(SeasonalCollectionEvents);
