import { gettext } from '@eventbrite/i18n';
import { Button } from '@eventbrite/marmalade';
import React from 'react';
import './SpainReliefRibbon.scss';

export const SpainReliefRibbon = (props: { browseSurface?: string }) => {
    const { browseSurface } = props;
    const ctaLink = `https://cercadeti.cruzroja.es/eventbritedana`;
    const ribbonCtaText = gettext('Cruz Roja.');

    return (
        <div className="relief-ribbon">
            <div className="relief-ribbon-container">
                <div
                    className="relief-ribbon-title-container"
                    data-testid="relief-ribbon-title"
                >
                    <h2 className="eds-text-bm ribbon-text"></h2>{' '}
                    {/* Empty h2 for CSS text */}
                </div>
                <div className="relief-ribbon-cta">
                    <a
                        href={ctaLink || ''}
                        data-heap-id={`relief${browseSurface}`}
                    >
                        <Button className="relief-ribbon-button">
                            <span className="eds-text-bm">{ribbonCtaText}</span>
                        </Button>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default SpainReliefRibbon;
