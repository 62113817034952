import loadable from '@loadable/component';
import React from 'react';
import { WaypointLazyRender } from '../../../../components/WaypointLazyRender';

const CollectionsBucketContainer = loadable(
    () => import('./CollectionsBucketContainer'),
);

export const LazyLoadedCollectionsBucket = () => {
    return (
        <WaypointLazyRender
            bottomOffset="-300px"
            emptyStateStyles={{ height: '300px' }}
        >
            <CollectionsBucketContainer />
        </WaypointLazyRender>
    );
};
