import { track } from '@eventbrite/datalayer-library';
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    IconButton,
} from '@eventbrite/marmalade';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    aus_holidayHeaderDataList,
    DOT_FILL_ACTIVE,
    DOT_FILL_INACTIVE,
    DOT_HEIGHT,
    DOT_RADIUS,
    DOT_WIDTH,
    ENGLISH_TLDs,
    FullbleedHeaderData,
    fullbleedHeaderDataList,
    holidayHeaderDataList,
    newYearHeaderDataList,
    TLD_AUSTRALIA,
} from '../../../../constants/constants';
import { useEnvContext } from '../../../../context';
import { DiscoverState } from '../../../../types';
import styles from './HomepageBannerCarousel.module.scss';
import { useIsMobileDevice, useIsTabletDevice } from './hooks';
import { makeImageSetString } from './utils';

const SIZES = '(max-width: 1920px) 1920px, 1924px';

const useAutoScroll = (isPaused: boolean, handleNextClick: () => void) => {
    useEffect(() => {
        if (isPaused) return;

        const autoScroll = setTimeout(() => {
            handleNextClick();
        }, 4000);

        return () => clearTimeout(autoScroll);
    }, [isPaused, handleNextClick]);
};

const getNextIndex = (currentIndex: number, length: number): number =>
    currentIndex === length - 1 ? 0 : currentIndex + 1;

const getPrevIndex = (currentIndex: number, length: number): number =>
    currentIndex === 0 ? length - 1 : currentIndex - 1;

const debounce = (func: (...args: any[]) => void, wait: number) => {
    let timeout: NodeJS.Timeout;
    return (...args: any[]) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), wait);
    };
};

const getHeaderDataList = (
    featureFlags: { [key: string]: boolean },
    tld: string,
) => {
    if (
        featureFlags.enableLighthouseCreatorsModule &&
        ENGLISH_TLDs.includes(tld as typeof ENGLISH_TLDs[number])
    )
        return newYearHeaderDataList;
    if (!featureFlags.enableSeasonalModule) return fullbleedHeaderDataList;
    if (tld === TLD_AUSTRALIA) return aus_holidayHeaderDataList;
    return ENGLISH_TLDs.includes(tld as typeof ENGLISH_TLDs[number])
        ? holidayHeaderDataList
        : fullbleedHeaderDataList;
};

const HomepageBannerCarousel: React.FC<{
    featureFlags: { [key: string]: boolean };
}> = ({ featureFlags }) => {
    const { env } = useEnvContext();
    const tld = env?.localeInfo?.tld || '.com';
    const headerDataList = getHeaderDataList(featureFlags, tld);
    const isMobile = useIsMobileDevice();
    const isTablet = useIsTabletDevice();
    const [currentIndex, setCurrentIndex] = useState(
        Math.floor(Math.random() * headerDataList.length),
    );
    const [isPaused, setIsPaused] = useState(false);
    const [touchStartX, setTouchStartX] = useState<number | null>(null);
    useEffect(() => {
        track({
            eventName: 'HomepageBannerCarouselView',
            eventData: {
                instrumentationText:
                    headerDataList[currentIndex]?.instrumentationText,
            },
        });
    }, [currentIndex, headerDataList]);
    const handleClick = (index: number) => {
        track({
            eventName: 'HomepageBannerCarouselClick',
            eventData: {
                instrumentationText: headerDataList[index]?.instrumentationText,
            },
        });
    };
    useEffect(() => {
        if (!headerDataList.length) return;
        const preloadImage = new Image();
        const firstImage =
            headerDataList[0].imageSet[
                isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop'
            ];
        preloadImage.src = firstImage;
    }, [headerDataList, isMobile, isTablet]);
    const handlePrevClick = debounce(() => {
        setCurrentIndex((prevIndex) =>
            getPrevIndex(prevIndex, headerDataList.length),
        );
    }, 100);

    const handleNextClick = debounce(() => {
        setCurrentIndex((prevIndex) =>
            getNextIndex(prevIndex, headerDataList.length),
        );
    }, 100);

    const handleTouchStart = (event: React.TouchEvent) => {
        setTouchStartX(event.touches[0].clientX);
        setIsPaused(true);
    };

    const handleTouchEnd = (event: React.TouchEvent) => {
        if (touchStartX === null) return;

        const touchEndX = event.changedTouches[0].clientX;
        const swipeThreshold = 50;

        if (touchStartX - touchEndX > swipeThreshold) {
            handleNextClick();
        } else if (touchEndX - touchStartX > swipeThreshold) {
            handlePrevClick();
        }

        setTouchStartX(null);
        setIsPaused(false);
    };

    const handleMouseEnter = () => setIsPaused(true);
    const handleMouseLeave = () => setIsPaused(false);

    useAutoScroll(isPaused, handleNextClick);

    return (
        <div className={`${styles.homepageCarouselContainer} eds-l-mar-bot-4`}>
            <div
                role="list"
                className={`${styles.bannerWrapper} eds-l-mar-top-4`}
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
            >
                {headerDataList.map((data, index) => {
                    const formattedImageSet = makeImageSetString(data.imageSet);

                    return (
                        <div
                            key={index}
                            className={`${styles.banner} eds-l-mar-bot-2`}
                        >
                            <a
                                href={data.ctaLink}
                                className={styles.overlay}
                                data-heap-id={`homepage_banner_carousel_click_${data.instrumentationText}`}
                                onClick={() => handleClick(index)}
                            >
                                <picture>
                                    <source
                                        media="(max-width: 660px)"
                                        srcSet={data.imageSet.mobile}
                                        type="image/webp"
                                    />
                                    <source
                                        media="(max-width: 802px)"
                                        srcSet={data.imageSet.tablet}
                                        type="image/webp"
                                    />
                                    <img
                                        //HTML Img Definition doesnt support fetchpriority but it
                                        //is supported in chrome and edge with broader support coming
                                        //https://caniuse.com/mdn-html_elements_img_fetchpriority
                                        //eslint-disable-next-line
                                        //@ts-ignore
                                        fetchpriority="high"
                                        srcSet={formattedImageSet.srcSet}
                                        src={formattedImageSet.default}
                                        alt={data.altText}
                                        sizes={SIZES}
                                        className={styles.image}
                                        loading="eager"
                                    />
                                </picture>
                            </a>
                        </div>
                    );
                })}
            </div>
            <Dots currentIndex={currentIndex} headerDataList={headerDataList} />
            {!(isMobile || isTablet) && (
                <IconButtons
                    handlePrevClick={handlePrevClick}
                    handleNextClick={handleNextClick}
                />
            )}
        </div>
    );
};

const Dots: React.FC<{
    currentIndex: number;
    headerDataList: FullbleedHeaderData[];
}> = ({ currentIndex, headerDataList }) => (
    <div className={styles.dotsContainer}>
        {headerDataList.map((_, index) => (
            <svg
                key={index}
                data-testid="dot"
                xmlns="http://www.w3.org/2000/svg"
                width={DOT_WIDTH}
                height={DOT_HEIGHT}
                viewBox="0 0 8 8"
                fill="none"
                className={
                    index === currentIndex
                        ? styles.activeDot
                        : styles.inactiveDot
                }
            >
                <circle
                    cx={DOT_RADIUS}
                    cy={DOT_RADIUS}
                    r={DOT_RADIUS}
                    fill={
                        index === currentIndex
                            ? DOT_FILL_ACTIVE
                            : DOT_FILL_INACTIVE
                    }
                />
            </svg>
        ))}
    </div>
);

const IconButtons: React.FC<{
    handlePrevClick: () => void;
    handleNextClick: () => void;
}> = ({ handlePrevClick, handleNextClick }) => (
    <div className={styles.iconButtonContainer}>
        <IconButton
            icon={<ChevronLeftIcon size="large" />}
            onClick={handlePrevClick}
            aria-label="Scroll carousel left"
            outline
        />
        <IconButton
            icon={<ChevronRightIcon size="large" />}
            onClick={handleNextClick}
            aria-label="Scroll carousel right"
            outline
        />
    </div>
);

const mapStateToProps = (state: DiscoverState) => ({
    featureFlags: state.app.featureFlags,
});

export default connect(mapStateToProps)(HomepageBannerCarousel);
