import { fetchJSON } from '@eventbrite/http';
import { formatUrl } from 'url-lib';
import { fetchThingsToDoShelfData } from '../../../../redux/actions/thingsToDoShelf';
import {
    setFeCallAsFailed,
    setFeCallAsOngoing,
    setFeCallAsSuccess,
} from '../app/actions';
import { getInitialState } from '../index';
import { UPDATE_ROOT_CONTEXT } from './rootActionTypes';

export const getRestOfContext =
    (originalContext: any) => async (dispatch: Function) => {
        dispatch(setFeCallAsOngoing());

        let response = {};
        try {
            response = await fetchJSON(formatUrl('/fe/'));
        } catch (e) {
            dispatch(setFeCallAsFailed());
        }

        const fullContext = getInitialState({
            ...originalContext,
            ...response,
        });

        dispatch({
            type: UPDATE_ROOT_CONTEXT,
            payload: fullContext,
        });
        dispatch(fetchThingsToDoShelfData());
        dispatch(setFeCallAsSuccess());
    };
